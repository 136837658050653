import React from "react";
import Banner from "../components/Banner";
import HomeAboutSection from "../components/HomeAboutSection";
import Industryweserve from "../components/Industryweserve";
import TopProducts from "../components/TopProducts";
import Cta from "../components/Cta.js";
import "./Home.scss";
import Newsandmedia from "../components/Newsandmedia";
import { Helmet } from "react-helmet";
import Slider from "../components/Slider";
import Productcat from "../components/Productcat";
import { useEffect } from "react";

function Home() {
  useEffect(() => {
    let sttop = () => {
      window.scrollTo(0, 0);
    }
    sttop()
  }, [])
  return (
    <div className="Home">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Textile Chemicals Manufacturers and Suppliers in Pakistan</title>
        <meta
          name="description"
          content="Hutaib  industrial solutions is a distinguished chemical supplier in Pakistan dealing with textile chemicals manufacturers, and offers broad range of chemicals."
          data-react-helmet="true"
        />
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Hutaib",
            alternateName: "Hutaib Industrial Solution",
            url: "https://hutaib.org/",
            logo: "https://hutaib.org/assets/logo/logo.png",
            contactPoint: [
              {
                "@type": "ContactPoint",
                telephone: "03097772468",
                contactType: "customer service",
                areaServed: "PK",
                availableLanguage: "en",
              },
            ],
            sameAs: [
              "https://www.facebook.com/HutaibIndustrialSolutions/",
              "https://pk.linkedin.com/in/hutaib-industrial-solutions-696204201",
            ],
          })}
        </script>

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "WebPage",
                url: "https://hutaib.org/",
                name: "Hutaib Textile Chemicals Manufacturers and Suppliers in Pakistan",
                description:
                  "Hutaib industrial solutions is a distinguished chemical supplier in Pakistan dealing with textile chemicals manufacturers, and offers broad range of chemicals.",
                inLanguage: "en-US",
                potentialAction: [
                  { "@type": "ReadAction", target: ["https://hutaib.org/"] },
                ],
              },
            ],
          })}
        </script>

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            name: "Hutaib Industrial Solutions | Chemical Importer & Suppliers",
            url: "https://hutaib.org/",
            image: "https://hutaib.org/assets/logo/logo.png",
            description:
              "Hutaib Industrial Solutions is a textile & construction chemical company in Pakistan; offering textile chemicals, construction and building materials, including waterproofing, SBR chemicals, silicone fluid, Hydrophilic silicone oil, Polydimethylsiloxane fluid, Epoxy, and Momentive Amino functional fluid. We are textile chemicals manufacturers and suppliers catering multiple industrial requirements.",
            hasMap:
              "https://www.google.com/maps/place/Hutaib+Industrial+Solutions+%7C+Chemical+Importer+%26+Suppliers/@24.8453678,67.0218373,17z/data=!4m5!3m4!1s0x3eb33de705ddfe63:0x1a67cdf796876b93!8m2!3d24.8453629!4d67.024026",
            address: {
              "@type": "PostalAddress",
              streetAddress:
                "Suite #309, Progressive Plaza, Beaumont Rd, near Pidc House, Civil Lines",
              addressLocality: "Karachi",
              addressRegion: "Sindh",
              addressCountry: "PK",
              postalCode: "75530",
            },
            telephone: "03097772468",
            geo: {
              "@type": "GeoCoordinates",
              latitude: "24.8452375",
              longitude: "67.0238114",
            },
            openingHoursSpecification: [
              {
                "@type": "OpeningHoursSpecification",
                dayOfWeek: "http://schema.org/Monday",
                opens: "09:00",
                closes: "18:00",
              },
              {
                "@type": "OpeningHoursSpecification",
                dayOfWeek: "http://schema.org/Tuesday",
                opens: "09:00",
                closes: "18:00",
              },
              {
                "@type": "OpeningHoursSpecification",
                dayOfWeek: "http://schema.org/Wednesday",
                opens: "09:00",
                closes: "18:00",
              },
              {
                "@type": "OpeningHoursSpecification",
                dayOfWeek: "http://schema.org/Thursday",
                opens: "09:00",
                closes: "18:00",
              },
              {
                "@type": "OpeningHoursSpecification",
                dayOfWeek: "http://schema.org/Friday",
                opens: "09:00",
                closes: "18:00",
              },
              {
                "@type": "OpeningHoursSpecification",
                dayOfWeek: "http://schema.org/Saturday",
                opens: "09:00",
                closes: "14:00",
              },
            ],
          })}
        </script>

        <meta
          data-react-helmet="true"
          property="twitter:image"
          content="https://hutaib.org/assets/logo/logo.png"
        />
        <meta
          data-react-helmet="true"
          property="twitter:description"
          content="Hutaib  industrial solutions is a distinguished chemical supplier in Pakistan dealing with textile chemicals manufacturers, and offers broad range of chemicals."
        />
        <meta
          data-react-helmet="true"
          property="twitter:title"
          content="Textile Chemicals Manufacturers and Suppliers in Pakistan"
        />
        <meta
          data-react-helmet="true"
          property="twitter:creator"
          content="@Hutaib"
        />
        <meta
          data-react-helmet="true"
          property="twitter:site"
          content="@Hutaib"
        />
        <meta
          data-react-helmet="true"
          property="twitter:card"
          content="summary_large_image"
        />
        <meta
          data-react-helmet="true"
          property="og:image"
          content="https://hutaib.org/assets/logo/logo.png"
        />
        <meta
          data-react-helmet="true"
          property="og:url"
          content="https://hutaib.org/"
        />
        <meta
          data-react-helmet="true"
          property="og:description"
          content="Hutaib  industrial solutions is a distinguished chemical supplier in Pakistan dealing with textile chemicals manufacturers, and offers broad range of chemicals."
        />

        <meta
          data-react-helmet="true"
          property="og:title"
          content="Textile Chemicals Manufacturers and Suppliers in Pakistan"
        />
        <meta
          data-react-helmet="true"
          property="og:site_name"
          content="Hutaib"
        />
        <meta
          data-react-helmet="true"
          property="fb:app_id"
          content="Hutaib Industrial Solutions"
        />
        <meta data-react-helmet="true" property="og:type" content="website" />
        <link
          data-react-helmet="true"
          rel="canonical"
          href="https://hutaib.org/"
        />
      </Helmet>

      <Banner />
      <Productcat />
      <TopProducts />
      <Industryweserve />
      <Slider />
      <Cta />
      <HomeAboutSection />
      <div className="seodesc">
        <h1>Textile Chemicals Suppliers in Pakistan</h1>
        <p>
          As one of the leading textile chemicals suppliers, we provide you
          every solution to soften and smoothen your fabric. Hutaib Industrial
          Solutions ranks amongst the most prominent chemical suppliers in
          Pakistan dealing with all sorts of petrochemicals and chemicals used
          in textile industry.
        </p>
        <p>
          We cater to every solution to fulfill your needs being the renowned
          textile chemical company and facilitate you with home protection,
          cloth softening, epoxies and many other different products that are
          exclusively demanded in the chemical industries.
        </p>
        <p>
          Mainly, our chemical products are delivered exclusively to all over
          Pakistan that includes the good quality of surfactants, textile
          chemicals, silicone fluids, and to keep you home safe and build – our
          construction chemicals do their part.
        </p>
      </div>
    </div>
  );
}

export default Home;
