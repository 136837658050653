import React from "react";
import "./css/Copyright.scss";

function Copyright() {
  return (
    <div className="Copyright">
      <p>
        2022 © Hutaib Industrial Solutions | Designed by <a href="https://simboz.com/" target='_blank'> Simboz</a>
      </p>
    </div>
  );
}

export default Copyright;
