import axios from 'axios'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import './Blogdetailpage.scss'
function Blogdetailpage() {


    function createMarkup(data) {
        return { __html: data };
    }

    let [blog, setBlog] = useState()
    let [blogid, setBlogid] = useState()

    let bid = window.location.href.split('/').at(-1)
    console.log(bid)
    let url = `https://hutaib.org/hutaib-admin/ProductsAPI/GetBlog/${blogid}`

    useEffect(() => {
        let sttop = () => {
            window.scrollTo(0, 0);
        }
        sttop()
        setTimeout(() => {
            setBlogid(bid)
            url = `https://hutaib.org/hutaib-admin/ProductsAPI/GetBlog/${bid}`

            // console.log(url)
            axios.get(url).
                then((res) => {
                    setBlog(res.data.blogs)

                    console.log(res.data.blogs.content)

                }).catch((err) => {
                    console.log(err);
                })
        }, 500);

        // let url = `https://hutaib.org/hutaib-admin/ProductsAPI/GetBlog/13`

    }, [blogid])

    console.log(blog)

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{blog && blog.title}</title>

                <meta
                    name="description"
                    content={blog && blog.meta_desc}
                    data-react-helmet="true"
                />
                <meta
                    name="robots"
                    content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
                />
            </Helmet>
            {blog && blog != '' ?
                <div className='blogdetailpage'>
                    <div className='blogdetailbanner'>
                        {/* <img src='/assets/images/products_banner.png' /> */}
                        <img src={blog && blog.desktop_banner_url} />


                    </div>

                    <div className='blogdetailwrapper'>
                        <h1>{blog && blog.title}</h1>

                        <div className='inner' dangerouslySetInnerHTML={{ __html: (blog && blog.content) ? JSON.parse(blog.content) : '' }}>

                            {/* <div className='inner'>
                    <p>Admixtures are used in concrete batches just before or during the concrete placement. Manufacturers mainly use Concrete Admixture to reduce the cost of concrete construction; transporting, arranging, and hardening purposes; and overcome emergencies during certain operations.</p>
                    <p>The successful use of these admixtures depends on the proper dosing method and the application of the infusion method. Most ready-to-use admixtures are in liquid or powder form and processed to the concrete at factories or construction sites.</p>
                    <p>Sometimes, concrete additives such as pigments, propellants and pump aids are combined in small amounts while other times pre-administered containers administer them manually.</p>
                    <h2>Use cases of Concrete Admixtures</h2>
                    <img src='/assets/images/blog/1.webp' />
                    <p>Admixtures are being used in Pakistan’s cement industry for long time based on their purposes. There are different classes of chemical additives, such as air entertainers, water reducers, retarders, accelerators, thermo-plasticizers, etc.</p>
                    <p>All graded additives fall into a specific category. Its uses cover corrosion protection, shrinkage, reduced alkali-silica reactivity, improved workability, binding, moisture proofing, and coloring.</p>
                    <p>Let’s explore the application of admixtures one by one.</p>
                    <h2>Set-Retarding Admixtures</h2>
                    <p>Set-Retarding admixtures run ideally to slow the rate of condensation of concrete and counteract the accelerating effect of hot weather on the setting of concrete. High temperatures often increase the cure rate, making it difficult to place and furnish. These types of Admixtures Used in Concrete retarders to keep the concrete available during paving and delay the initial setup of the concrete. Most retarders also act as thinners, trapping air to flow in concrete.</p>
                    <h2>Water Reducing Admixture</h2>
                    <img src='/assets/images/blog/2.webp' />
                    <p>Admixtures are being used in Pakistan’s cement industry for long time based on their purposes. There are different classes of chemical additives, such as air entertainers, water reducers, retarders, accelerators, thermo-plasticizers, etc.</p>
                    <p>All graded additives fall into a specific category. Its uses cover corrosion protection, shrinkage, reduced alkali-silica reactivity, improved workability, binding, moisture proofing, and coloring.</p>
                    <p>Let’s explore the application of admixtures one by one.</p>
                    <h2>Set-Retarding Admixtures</h2>
                    <p>Set-Retarding admixtures run ideally to slow the rate of condensation of concrete and counteract the accelerating effect of hot weather on the setting of concrete. High temperatures often increase the cure rate, making it difficult to place and furnish. These types of Admixtures Used in Concrete retarders to keep the concrete available during paving and delay the initial setup of the concrete. Most retarders also act as thinners, trapping air to flow in concrete.</p>
                    <h2>Water Reducing Admixture</h2>
                    <img src='/assets/images/blog/3.webp' />
                    <p>Admixtures are being used in Pakistan’s cement industry for long time based on their purposes. There are different classes of chemical additives, such as air entertainers, water reducers, retarders, accelerators, thermo-plasticizers, etc.</p>
                    <p>All graded additives fall into a specific category. Its uses cover corrosion protection, shrinkage, reduced alkali-silica reactivity, improved workability, binding, moisture proofing, and coloring.</p>
                    <h2>Conclusion</h2>
                    <p>Admixtures are necessary to improve concrete quality, controllability, faster or slower setting times, and other modified properties to achieve specific building results. Many, if not all, concrete mixes today contain one or more Admixtures in the concrete that can aid in the pouring process, increasing productivity while reducing costs.</p> */}
                        </div>
                    </div>

                </div >
                :
                <div className='loader'>
                    <img src='../assets/images/loader.gif' />
                </div>
            }
        </>
    )
}

export default Blogdetailpage
